<template>
  <div class="background">
    <div style="width:90%">
       <div class="Title" >
      <div class="title">案例详情</div>
      <div class="more" @click="seeMore"> >>查看详情</div>
    </div>
    <div class="typeSwitcher">

      <div class="typeChoice">
        <div>
          <a @click=getInfo(0)>全部</a>
          <i v-show="type === 0"></i>
        </div>
        <div>
          <a @click=getInfo(1)>医疗设备</a>
          <i v-show="type === 1"></i>
        </div>
        <div>
          <a @click=getInfo(2)>仪器设备</a>
          <i v-show="type === 2"></i>
        </div>
        <div>
          <a @click=getInfo(3)>消费电子</a>
          <i v-show="type === 3"></i>
        </div>
        <div>
          <a @click=getInfo(4)>交通出行</a>
          <i v-show="type === 4"></i>
        </div>
        <div>
          <a @click=getInfo(5) >工业装备</a>
          <i v-show="type === 5"></i>
        </div>
        <div>
          <a @click=getInfo(6)>机器人</a>
          <i v-show="type === 6"></i>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <div v-for="(item,i) in cases" class="item">
          <div style="cursor: pointer" @click="this.$router.push({path:'/CaseDetail',query: {caseId: item.id}})">
            <div class="pic">
              <img :src="item.pic" alt="" style="width: 3.99rem;  height: 2.56rem;">
            </div>
            <div class="name">
               <span>{{item.caseName}}</span>
              <div class="browse">
                <i class="fa fa-eye" style="font-size:.16rem"></i>
                <i>136</i>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CasesComponents",
  data(){
    return{
      type:'',
      Choice:{
        type1:"产品设计",
        type2:"全部",
        pageSize: 20,
      },
      cases:[]
    }
  },
  mounted() {
    this.getInfo("全部");
  },
  methods:{
    getInfo(type){
      switch (type){
        case 0: this.Choice.type2 = "全部";break;
        case 1: this.Choice.type2 = "医疗设备";break;
        case 2: this.Choice.type2 = "仪器设备";break;
        case 3: this.Choice.type2 = "消费电子";break;
        case 4: this.Choice.type2 = "交通出行";break;
        case 5: this.Choice.type2 = "工业装备";break;
        case 6: this.Choice.type2 = "机器人";break;
      }
      this.$http({
        method: 'post',
        url: '/showCasesByTypes',
        data: this.Choice
      }).then(res => {
            this.cases = res.data;
          }
      )},
    seeMore(){
      this.$router.push('/Case');
    },
  },
}
</script>

<style scoped>
.background{
  display: flex;
  width: 100%;
  height: 20rem;
  justify-content: center;
}

.Title{
  height: .54rem;
  padding-left: .35rem;
  margin-bottom: .55rem;
}

.Title .title{
  float: left;
  color: rgb(235,66,77);
  font-size: .36rem;
  font-weight: 500;
}

.Title .more{
  float: right;
  color: black;
  font-size: .35rem;
  font-weight: 400;
  cursor: pointer;
  margin-right: 0.5rem;
}

.Title .more:hover{
  color: rgb(235,66,77);
}

.typeSwitcher .typeTitle{
  font-size: .24rem;
  font-weight: 300;
  margin-bottom: .24rem;
}

.typeChoice{
  width: 100%;
  height: .39rem;
  font-size: .26rem;
  font-weight: 400;
}


.typeChoice div {
  float: left;
  width: 1.66rem;
  height: .39rem;
  cursor: pointer;
}

.typeChoice div:nth-of-type(1){
  margin-left: .35rem;
}

.typeChoice div:nth-last-child(1)::after{
  content:'|';
  width: .01rem;
}

.typeChoice div:nth-last-child(1){
  width: 1.75rem;
}

.typeChoice div a{
  display: inline-block;
  width: 1.55rem;
  height: .39rem;
  line-height: .39rem;
  text-align: center;
  margin-left: .03rem;
}

.typeChoice div:before{
  content:'|';
  width: .01rem;
}

.content{
  width: 19.2rem;
  padding-top: .79rem;
  padding-left: .32rem;
}

.content .item{
  width: 3.99rem;
  height: 2.85rem;
  float: left;
  background-color: white;
  margin-bottom: .55rem;
  margin-right: .33rem;
}


.content .item .pic{
  width: 3.99rem;
  height: 2.56rem;
  margin-bottom: .08rem
}

.content .item .name{
  position: relative;
  height: .16rem;
  line-height: .16rem;
}

.content .item .name span{
  float: left;
  height: .16rem;
  font-size: .16rem;
  font-weight: 500;
  cursor: pointer;
  color: #212121;
  margin-left: .22rem;
}

.content .item .name .browse{
  position: absolute;
  right: .37rem;
  float: left;
  height: .16rem;
  line-height: .16rem;
}

.content .item .name .browse .fa-eye{
  margin-right: .07rem;
}


.content .item .name:hover{
  color: #ec414d;
}
</style>
