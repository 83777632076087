<template>
  <div class="part3">

  </div>
</template>

<script>
export default {
  name: "Bar"
}
</script>

<style scoped>
.part3{
  position: relative;
  height: 2rem;
  background: url("../../images/part3_pic.png") center;
}


</style>