<template>
  <el-carousel height="9.5rem">
    <el-carousel-item v-for="item in homePic" :key="item" :pause-on-hover="false" :interval="2000">
      <img :src="item.src" style="height:100%;width:100%;"/>
<!--      <img src="../../images/activityBanner.png" style="height:100%;width:100%;" alt="图片丢失了" />-->
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "Carousel",
  data(){
    return{
      homePic:[],
    }
  },
  created() {
    this.getHomePic();
  },

  methods:{
    getHomePic(){
      this.$http({
        method: 'post',
        url: '/getHomeHeaderPics',
        data: '',
      }).then(res => {
        for(let i=0;i<res.data.homePics.length;i++){
          this.homePic.push({
            src: res.data.homePics[i],
          })
        }
      })
    },

  }
}
</script>

<style scoped>

</style>
