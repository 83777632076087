<template>
  <link rel="stylesheet" href="src/assets/normalize.css">
  <div class="background">
    <div class="part1-2">
      <div class="bigBox">
        <div class="part1">
          <el-carousel height="9.5rem">
            <!--轮播图-->
            <Carousel/>
          </el-carousel>
          <!-- <div class="title">
            <p>云端协力，</p>
            <p>齐心制造，</p>
            <p>实现共享共赢</p>
            <button class="down" @click="this.$router.push('/RequestDetail')" v-show="isLogin === '1' && this.role === 'enterprise' ">发布需求</button>
          </div> -->
        </div>
      </div>
    </div>
    <Part2/>
    <Bar />

    <div style="padding-top: .5rem;">
      <CasesComponents />
    </div>
    <Bar />
    <div style="padding-top: .5rem;">
      <ProjectComponents />
    </div>
  </div>
</template>

<script>
import Carousel from "@/views/Home/Carousel";
import Part2 from "@/components/HomeComponents/Part2";
import Bar from"@/components/HomeComponents/Bar"
import CasesComponents from "@/components/HomeComponents/CasesComponents";
import ProjectComponents from "@/components/HomeComponents/ProjectComponents";
export default {
  name: 'Home',
  data(){
    return{
      isLogin:'0',
      role:'',

    }
  },
  mounted() {
    this.isLogin = window.sessionStorage.getItem("login");
    this.role = window.sessionStorage.getItem("role");
  },
  methods:{

  },
  components: {
    Carousel,
    Part2,
    Bar,
    CasesComponents,
    ProjectComponents,
  }
}
</script>

<style scoped>
.background{
  background-color: white;
}

video{
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.title{
  position: absolute;
  top: 1.75rem;
  left: 1.8rem;
  height: 4.42rem;

  font-weight: 300;
  font-size: .82rem;
  /*background-color: pink;*/
}

.title p{
  display: block;
  font-size: .9rem;
  color: #ffffff;
  width: 5.4rem;
  height: .76rem;
  margin-bottom: .3rem;
  margin-top: 0;
}

.title :nth-child(3){
  margin-bottom: .95rem;
}

.title button{
  /*position: relative;*/
  float: left;
  width: 1.65rem;
  height: .6rem;
  font-size: .21rem;
  line-height: .6rem;
  text-align: center;
  /*border: .01rem solid transparent;*/
  background-color: #ec414d;
  /*border: .01rem solid transparent;*/
  margin-right: .32rem;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}


.title button:hover{
  background-size: 300%;

  color: #ffffff;
}

.part1{
  /* height: 9.32rem; */
  width: 100%;
}

.part1 .part1Title div:nth-child(1){
  height: .36rem;
  font-size: .36rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: .3rem;
  margin-top: .7rem;
  color: #ffffff;
  line-height: .4rem;
  /*letter-spacing: .05rem;*/
}
.part1 .part1Title div:nth-child(2){
  height: .26rem;
  font-size: .24rem;

  text-align: center;
  margin-bottom: .1rem;
  color: #ffffff;
  line-height: .26rem;
  /*letter-spacing: .05rem;*/

}
.part1 .part1Title div:nth-child(3){
  height: .26rem;
  font-size: .24rem;

  text-align: center;
  /*margin-bottom: .1rem;*/
  color: #ffffff;
  line-height: .26rem;
  /*letter-spacing: .05rem;*/

}

.part1-2 .bigBox{
  position: relative;
}

</style>
