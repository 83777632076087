<template>
  <div class="background">
    <div style="width:90%">
      <div class="Title">
        <div class="title">项目中心</div>
        <div class="more" @click="seeMore">>>查看更多</div>
      </div>
      <div>
        <div class="table">
          <el-scrollbar max-height="16.55rem">
            <div class="tableColumn" v-for="(item, i) in projects">
              <div class="pic">
                <img
                  :src="item.pic"
                  alt=""
                  style="width: 5.4rem; height: 3rem"
                />
              </div>
              <div class="top">
                <div class="data">
                  <div class="dataDay">
                    {{ item.day }}
                  </div>
                  <div class="dataMonth">
                    {{ item.yearAndMonth }}
                  </div>
                </div>
                <div class="name">
                  {{ item.projectName }}
                </div>
                <div class="recruit">
                  {{ item.stage }}
                </div>
              </div>
              <div class="bottom">
                <div class="num">
                  <p>招募人数</p>
                  <p>{{ item.needNum }}</p>
                </div>
                <div class="deadLine">
                  <p>剩余时间</p>
                  <p>{{ itemNaNpxainingTime }}</p>
                </div>
                <div class="budget">
                  <p>项目预算</p>
                  <p>{{ item.budget }}</p>
                </div>
                <button @click="Detail(item.id)">查看详情</button>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectHome",
  data() {
    return {
      Choice: {
        type2: [""],
        timeType: "一年内",
        stage: "全部",
        transactionMode: "",
        currentPage: 1,
        pageSize: 30,
      },
      projects: {},
      checkedCities: [],
      checkedCities2: [],
      checkedCities3: [],
      checkedCities4: [],
      checkedCities5: [],
      isCollapse: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    seeMore() {
      this.$router.push("/ProjectHome");
    },
    getInfo() {
      this.$http({
        method: "post",
        url: "/showProjects",
        data: this.Choice,
      }).then((res) => {
        this.projects = res.data.projects;
        for (let i = 0; i < this.projects.length; i++) {
          if (this.projects[i].stage === 1) {
            this.projects[i].stage = "报名中";
          }
        }
      });
    },
    Detail(id) {
      window.sessionStorage.setItem("id", id);
      this.$router.push("/Introduce");
    },
  },
};
</script>

<style scoped>
.background {
  display: flex;
  width: 100%;
  height: 13.7rem;
  justify-content: center;
}
.Title {
  width: 100%;
  height: 0.54rem;
  /* padding-left: 1.6rem; */
  margin-bottom: 0.44rem;
}

.Title .title {
  float: left;
  color: rgb(235, 66, 77);
  font-size: 0.36rem;
  font-weight: 500;
}

.Title .more {
  float: right;
  color: black;
  font-size: 0.3rem;
  font-weight: 400;
  cursor: pointer;
  margin-right: 0.3rem;
}

.Title .more:hover {
  color: rgb(235, 66, 77);
}

.table {
  width: 19.2rem;
}

.tableColumn {
  float: left;
  width: 5.4rem;
  height: 5.55rem;
  margin-right: 0.4rem;
  background-color: #ffffff;
  margin-bottom: 0.5rem;
  box-shadow: 0.01rem 0.01rem 0.1rem 0.01rem rgba(0, 0, 0, 0.3);
}

.tableColumn .pic {
  width: 5.4rem;
  height: 3rem;
  margin-bottom: 0.28rem;
}

.tableColumn .top {
  height: 0.84rem;
  padding-left: 0.33rem;
  margin-bottom: 0.44rem;
}

.tableColumn .data {
  float: left;
  width: 0.84rem;
  height: 0.84rem;
  /*margin-top: 1.05rem;*/
  padding-top: 0.18rem;
  text-align: center;
  /*line-height: 1.25rem;*/
  color: rgb(235, 66, 77);
  /*background-color: #1ea1e0;*/
  border-radius: 0.05rem;
  box-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.2);
}

.tableColumn .data .dataDay {
  height: 0.22rem;
  font-size: 0.22rem;
  line-height: 0.22rem;
  font-weight: 500;
  color: rgb(235, 66, 77);
  margin-bottom: 0.1rem;
}

.tableColumn .data .dataMonth {
  height: 0.1rem;
  font-size: 0.1rem;
  line-height: 0.1rem;
  color: rgb(235, 66, 77);
}

.tableColumn .projectName {
  float: left;
  height: 0.24rem;
  /*width: 3.6rem;*/
  font-size: 0.24rem;
  color: #212121;
  font-weight: 500;
  margin-left: 1.24rem;
  margin-top: -0.85rem;
  /*margin-top: .65rem;*/
}

.tableColumn .name {
  float: left;
  height: 0.84rem;
  font-size: 0.28rem;
  color: #212121;
  line-height: 0.84rem;
  font-weight: 500;
  margin-left: 0.22rem;
}

.tableColumn .recruit {
  float: left;
  /*margin-left: .96rem;*/
  width: 0.73rem;
  height: 0.28rem;
  font-size: 0.13rem;
  text-align: center;
  line-height: 0.28rem;
  background-color: #ed414d;
  color: #ffffff;
  border-radius: 0.05rem;
  margin-top: 0.27rem;
  margin-left: 1.47rem;
}

.tableColumn .bottom {
  width: 5.36rem;
  height: 0.69rem;
}

.tableColumn .num {
  float: left;
  /* width: 0.56rem; */
  height: 0.5rem;
  margin-left: 0.35rem;
  text-align: center;
}

.tableColumn .num p:nth-child(1) {
  font-size: 0.14rem;
  color: #9e9e9e;
  margin-bottom: 0.1rem;
}

.tableColumn .num p:nth-child(2) {
  font-size: 0.18rem;
  color: #212121;
}

.tableColumn .deadLine {
  float: left;
  height: 0.5rem;
  margin-left: 0.4rem;
  text-align: center;
}

.tableColumn .deadLine p:nth-child(1) {
  font-size: 0.14rem;
  color: #9e9e9e;
  margin-bottom: 0.1rem;
}

.tableColumn .deadLine p:nth-child(2) {
  font-size: 0.18rem;
  color: #212121;
}

.tableColumn .budget {
  float: left;
  /* width: s0.85rem; */
  height: 0.75rem;
  margin-left: 0.4rem;
  text-align: center;
}

.tableColumn .budget p:nth-child(1) {
  font-size: 0.14rem;
  color: #9e9e9e;
  margin-bottom: 0.1rem;
}

.tableColumn .budget p:nth-child(2) {
  font-size: 0.18rem;
  color: #212121;
}

.tableColumn button {
  float: right;
  width: 1.38rem;
  height: 0.49rem;
  /* margin-left: 0.34rem; */
  color: #fef6f6;
  background-color: #212121;
  border-radius: 0.03rem;
  font-size: 0.2rem;
  cursor: pointer;
  background-image: linear-gradient(115deg, transparent 50%, #ec414d 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all 0.7s;
}

.tableColumn button:hover {
  background-size: 300%;
}
</style>